.greetings {
  @apply p-5 text-center;

  & .greetingsHeader {
    @apply text-3xl my-4;
  }

  & .greetingsText {
    @apply text-lg my-1;
  }
}