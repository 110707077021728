.footer {
  @apply container mx-auto min-h-[60px]  bg-sky-800 text-sky-200 rounded-t-md;

  & .wrapperFooter {
    @apply max-w-7xl mx-auto h-full flex items-center justify-between p-5;
  }
  & .wrapperFooter {
    @apply sm:flex-col gap-y-2;
  }
}

.school-logo {
  @apply w-24;
  filter: invert(84%) sepia(21%) saturate(688%) hue-rotate(176deg) brightness(103%) contrast(98%);
}
.git-link {
  @apply flex items-center justify-between gap-x-1;

  & a {
    @apply cursor-pointer;
    &:hover {
      @apply text-sky-400;
    }
  }

  & img {
    @apply w-10 cursor-pointer;
  }
}
