.soundControlBtn{
  @apply w-32 text-3xl flex justify-center border-2 rounded-3xl border-gray-300 text-center transition-all duration-300;

  &:hover {
    @apply bg-sky-200;
  }

  &:active {
    @apply bg-sky-400 border-transparent;
  }
}