.loginBtn {
  @apply block w-full bg-sky-600 py-2 px-4 rounded-2xl hover:text-sky-200 hover:-translate-y-1 transition-all duration-500 text-white font-semibold;
}

/* .mobileMenuBtn {
  @apply w-32 border-b my-2 uppercase rounded-lg px-3 py-2 text-sky-200 font-medium bg-sky-400;
  &:hover {
    @apply bg-sky-200 text-sky-800;
  }
} */
