.login {
  @apply flex items-center justify-center;

  & .form {
    @apply w-100 xs:w-full bg-white rounded-md shadow-2xl p-5 m-4;

    & .form__header {
      @apply text-gray-800 font-bold text-2xl mb-1;
    }

    & .form__sub-header {
      @apply text-sm font-normal text-gray-600 mb-4;
    }

    & .form__input-container {
      @apply flex items-center border-2 py-2 px-3 rounded-2xl;
    }

    & .form__input-container_invalid {
      @apply border-red-400;
    }

    & .form__error {
      @apply h-5;

      & .form__error-message {
        @apply pl-4 m-0 text-xs text-red-400;
      }
    }

    & .icon {
        @apply h-5 w-5 text-gray-400;
    }

    & .form__input {
    @apply pl-2 w-full outline-none border-none;
    }

    & .form__last {
      @apply  flex items-center border-2 py-2 px-3 rounded-2xl;
    }

    & .form__login-btn {
      @apply block w-full text-center bg-sky-600 mt-3 py-2 rounded-2xl hover:bg-sky-700 hover:-translate-y-1 transition-all duration-500 text-white font-semibold mb-2;
    }

    & .form__sign-container {
      @apply  flex justify-center mt-4;
    }

    & .form__sign-up-btn {
      @apply text-sm hover:text-sky-500 cursor-pointer hover:-translate-y-1 duration-500 transition-all;
    }
   }
}