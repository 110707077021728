.resultLine {
  @apply flex h-10 items-center;

  & .word {
    @apply ml-2 font-bold;
  }

  & .dash {
    @apply mx-2;
  }

  & .translation {
    @apply ml-0;
  }
}