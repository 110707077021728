.carousel {
  @apply w-5/6 flex justify-center items-center mb-10 mt-4 mx-auto sm:w-full;

  & .carouselContainer {
    @apply w-full overflow-hidden;

    & .slider {
      @apply whitespace-nowrap inline-block shadow-lg;
      & .slide {
        @apply w-full whitespace-normal text-center inline-block relative;
      }
    }
  }
}

.sliderImg {
  @apply w-full mx-auto rounded-xl max-h-96 object-cover object-center sm:inline-block;
  transition: background 0.2s linear;
}

.sliderArrowLeft,
.sliderArrowRight {
  @apply w-8 h-8 text-sky-300 cursor-pointer;
}
.sliderArrowLeft {
  @apply -order-1 mr-1;
}
.sliderArrowRight {
  @apply ml-1;
}

.sign {
  @apply absolute z-10 left-1/2 top-1/2 text-sky-900 text-3xl italic sm:text-base w-full;
  transform: translate(-50%, -50%);
}
