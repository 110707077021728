/*Обнуление*/
* {
  @apply p-0 m-0 border-x-0 border-y-0;
}

html,
body {
  @apply h-full w-full scroll-smooth;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  @apply bg-sky-50;
}

.wrapper {
  @apply min-h-screen flex flex-col;
}
main {
  @apply flex-auto max-w-7xl mx-auto w-full;
}
