.answerBtnContainer {
  @apply flex justify-center flex-wrap mb-5;

  & .answerBtn {
    @apply border uppercase px-3 py-2 my-2 mx-2 rounded-full text-center cursor-pointer transition-all duration-300;
  }

  & .activeAnswerBtn {
    @apply hover:bg-sky-200 active:bg-sky-400 border-gray-400;
  }
}


