.pagination {
  @apply flex my-2 justify-center items-center gap-1;

  & .prevBtn,
  .nextBtn,
  .lastBtn,
  .firstBtn {
    @apply flex  border-b my-2 uppercase rounded-lg px-3 py-2 text-sky-200  font-medium bg-sky-700 disabled:opacity-40 transition-all duration-300;

    &:not([disabled]):hover {
      @apply bg-sky-200 text-sky-800 cursor-pointer;
    }
  }
  & .currentPage {
    @apply uppercase w-10 py-2 px-3 leading-tight rounded-lg  text-sky-800 bg-sky-50 border text-center;
  }
}
