.gamesPage {
  @apply flex flex-col items-center justify-center px-16 md:px-4; 
}

.gameSection {
  @apply w-full flex flex-col align-middle items-center justify-center bg-sky-100 p-5 rounded-2xl;

  & .nextRound {
    @apply w-40 border-2 border-gray-400 uppercase py-2 px-6 my-2 rounded-full hover:bg-sky-200 active:bg-sky-400 active:border-transparent;

    &:disabled {
      @apply text-gray-400 bg-transparent hover:bg-transparent active:border-gray-400;
    }
  }

  & .cardAudio {
    @apply scale-150 p-3 rounded-full border-2 border-gray-500 my-6 transition-all duration-300;

    &:hover {
      @apply bg-sky-200;
    }
  
    &:active {
      @apply bg-sky-400 border-transparent;
    }
  }

  & .upperGamePart {
    @apply w-full flex justify-between items-center sm:flex-col; 

    & .roundCounter{
      @apply w-32 text-3xl text-center;
    }

    & .soundOff{
      @apply w-32;
    }
  }
}

.gameName {
  @apply text-3xl;
}



.answerContainer {
  @apply h-10 my-3 uppercase text-2xl;
}

.answerImg {
  @apply w-40 h-40 bg-cover bg-no-repeat bg-center my-auto rounded-full;
}

.givenAnswer {
  @apply bg-sky-400;
}

.answerCorrect {
  @apply text-green-700;
}

.answerIncorrect {
  @apply text-red-700;
}

.endGame {
  @apply flex flex-col items-center;

  & .restartBtn {
    @apply w-52 border-2 border-gray-400 uppercase py-2 px-6 my-2 rounded-full hover:bg-sky-200 active:bg-sky-400 transition-all duration-300 active:border-transparent;
  }
}

